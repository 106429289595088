'use strict';

const autoload = () => {
    // Aplicar máscaras de entradas automaticamente em campos identificados
    if ($.fn.inputmask) {
        $('[data-mask="date"]').inputmask('99/99/9999');
        $('[data-mask="cnpj"]').inputmask('99.999.999/9999-99');
        $('[data-mask="cpf"]').inputmask('999.999.999-99');
        $('[data-mask="cpfCnpj"]').inputmask('(999.999.999-99)|(99.999.999/9999-99)');
        $('[data-mask="tel"]').inputmask("(99)9999[9]-9999");
        $('[data-mask="zipcode"]').inputmask("99999-999");
        $('[data-mask="decimal"]').inputmask("(.999){+|1},00", {
            positionCaretOnClick: "radixFocus",
            radixPoint: ",",
            _radixDance: true,
            numericInput: true,
            placeholder: "0",
            definitions: {
                "0": {
                    validator: "[0-9\uFF11-\uFF19]"
                }
            }
        });
    } else {
        console.error('inputmask not included');
    }

    if ($.fn.maskMoney) {
        $('[data-mask="money"]').maskMoney({
            thousands: '.',
            decimal: ',',
            allowZero: true,
            reverse: false,
            selectAllOnFocus: true,
            allowEmpty: true,
            allowNegative: true
        });
    } else {
        console.error('maskMoney not included');
    }

    // Marca campos de formulários requeridos
    $('*[required]').each(function () {
        const id = $(this).attr('id');
        const label = $(`label[for="${id}"]`);
        const mark = '<span class="text-danger">*</span>';
        if (!label.find('span.text-danger')[0]) label.append(mark);
    });

    // console.log("%c✔ Autoload run", "color: green");

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    // DataTables Default Config
    if ($.fn.dataTable) {
        const DTCommonConfig = {
            dom: "<'row'<'col-12 mb-4'B>>" +
                "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            responsive: true,
            serverSide: true,
            searching: false,
            pageLength: 20,
            lengthChange: false,
            language: {
                "emptyTable": "Nenhum registro encontrado",
                "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                "infoFiltered": "(Filtrados de _MAX_ registros)",
                "infoThousands": ".",
                "loadingRecords": "Carregando...",
                "zeroRecords": "Nenhum registro encontrado",
                "search": "Pesquisar",
                "paginate": {
                    "next": "Próximo",
                    "previous": "Anterior",
                    "first": "Primeiro",
                    "last": "Último"
                },
                "aria": {
                    "sortAscending": ": Ordenar colunas de forma ascendente",
                    "sortDescending": ": Ordenar colunas de forma descendente"
                },
                "select": {
                    "rows": {
                        "_": "Selecionado %d linhas",
                        "1": "Selecionado 1 linha"
                    },
                    "cells": {
                        "1": "1 célula selecionada",
                        "_": "%d células selecionadas"
                    },
                    "columns": {
                        "1": "1 coluna selecionada",
                        "_": "%d colunas selecionadas"
                    }
                },
                "buttons": {
                    "copySuccess": {
                        "1": "Uma linha copiada com sucesso",
                        "_": "%d linhas copiadas com sucesso"
                    },
                    "collection": "Coleção  <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
                    "colvis": "Visibilidade da Coluna",
                    "colvisRestore": "Restaurar Visibilidade",
                    "copy": "Copiar",
                    "copyKeys": "Pressione ctrl ou u2318 + C para copiar os dados da tabela para a área de transferência do sistema. Para cancelar, clique nesta mensagem ou pressione Esc..",
                    "copyTitle": "Copiar para a Área de Transferência",
                    "csv": "CSV",
                    "excel": "Excel",
                    "pageLength": {
                        "-1": "Mostrar todos os registros",
                        "_": "Mostrar %d registros"
                    },
                    "pdf": "PDF",
                    "print": "Imprimir",
                    "createState": "Criar estado",
                    "removeAllStates": "Remover todos os estados",
                    "removeState": "Remover",
                    "renameState": "Renomear",
                    "savedStates": "Estados salvos",
                    "stateRestore": "Estado %d",
                    "updateState": "Atualizar"
                },
                "autoFill": {
                    "cancel": "Cancelar",
                    "fill": "Preencher todas as células com",
                    "fillHorizontal": "Preencher células horizontalmente",
                    "fillVertical": "Preencher células verticalmente"
                },
                "lengthMenu": "Exibir _MENU_ resultados por página",
                "searchBuilder": {
                    "add": "Adicionar Condição",
                    "button": {
                        "0": "Construtor de Pesquisa",
                        "_": "Construtor de Pesquisa (%d)"
                    },
                    "clearAll": "Limpar Tudo",
                    "condition": "Condição",
                    "conditions": {
                        "date": {
                            "after": "Depois",
                            "before": "Antes",
                            "between": "Entre",
                            "empty": "Vazio",
                            "equals": "Igual",
                            "not": "Não",
                            "notBetween": "Não Entre",
                            "notEmpty": "Não Vazio"
                        },
                        "number": {
                            "between": "Entre",
                            "empty": "Vazio",
                            "equals": "Igual",
                            "gt": "Maior Que",
                            "gte": "Maior ou Igual a",
                            "lt": "Menor Que",
                            "lte": "Menor ou Igual a",
                            "not": "Não",
                            "notBetween": "Não Entre",
                            "notEmpty": "Não Vazio"
                        },
                        "string": {
                            "contains": "Contém",
                            "empty": "Vazio",
                            "endsWith": "Termina Com",
                            "equals": "Igual",
                            "not": "Não",
                            "notEmpty": "Não Vazio",
                            "startsWith": "Começa Com",
                            "notContains": "Não contém",
                            "notStartsWith": "Não começa com",
                            "notEndsWith": "Não termina com"
                        },
                        "array": {
                            "contains": "Contém",
                            "empty": "Vazio",
                            "equals": "Igual à",
                            "not": "Não",
                            "notEmpty": "Não vazio",
                            "without": "Não possui"
                        }
                    },
                    "data": "Data",
                    "deleteTitle": "Excluir regra de filtragem",
                    "logicAnd": "E",
                    "logicOr": "Ou",
                    "title": {
                        "0": "Construtor de Pesquisa",
                        "_": "Construtor de Pesquisa (%d)"
                    },
                    "value": "Valor",
                    "leftTitle": "Critérios Externos",
                    "rightTitle": "Critérios Internos"
                },
                "searchPanes": {
                    "clearMessage": "Limpar Tudo",
                    "collapse": {
                        "0": "Painéis de Pesquisa",
                        "_": "Painéis de Pesquisa (%d)"
                    },
                    "count": "{total}",
                    "countFiltered": "{shown} ({total})",
                    "emptyPanes": "Nenhum Painel de Pesquisa",
                    "loadMessage": "Carregando Painéis de Pesquisa...",
                    "title": "Filtros Ativos",
                    "showMessage": "Mostrar todos",
                    "collapseMessage": "Fechar todos"
                },
                "thousands": ".",
                "datetime": {
                    "previous": "Anterior",
                    "next": "Próximo",
                    "hours": "Hora",
                    "minutes": "Minuto",
                    "seconds": "Segundo",
                    "amPm": [
                        "am",
                        "pm"
                    ],
                    "unknown": "-",
                    "months": {
                        "0": "Janeiro",
                        "1": "Fevereiro",
                        "10": "Novembro",
                        "11": "Dezembro",
                        "2": "Março",
                        "3": "Abril",
                        "4": "Maio",
                        "5": "Junho",
                        "6": "Julho",
                        "7": "Agosto",
                        "8": "Setembro",
                        "9": "Outubro"
                    },
                    "weekdays": [
                        "Dom",
                        "Seg",
                        "Ter",
                        "Qua",
                        "Qui",
                        "Sex",
                        "Sáb"
                    ]
                },
                "editor": {
                    "close": "Fechar",
                    "create": {
                        "button": "Novo",
                        "submit": "Criar",
                        "title": "Criar novo registro"
                    },
                    "edit": {
                        "button": "Editar",
                        "submit": "Atualizar",
                        "title": "Editar registro"
                    },
                    "error": {
                        "system": 'Ocorreu um erro no sistema.'
                    },
                    "multi": {
                        "noMulti": "Essa entrada pode ser editada individualmente, mas não como parte do grupo",
                        "restore": "Desfazer alterações",
                        "title": "Múltiplos valores",
                        "info": "Os itens selecionados contêm valores diferentes para esta entrada. Para editar e definir todos os itens para esta entrada com o mesmo valor, clique ou toque aqui, caso contrário, eles manterão seus valores individuais."
                    },
                    "remove": {
                        "button": "Remover",
                        "confirm": {
                            "_": "Tem certeza que quer deletar %d linhas?",
                            "1": "Tem certeza que quer deletar 1 linha?"
                        },
                        "submit": "Remover",
                        "title": "Remover registro"
                    }
                },
                "decimal": ",",
                "stateRestore": {
                    "creationModal": {
                        "button": "Criar",
                        "columns": {
                            "search": "Busca de colunas",
                            "visible": "Visibilidade da coluna"
                        },
                        "name": "Nome:",
                        "order": "Ordenar",
                        "paging": "Paginação",
                        "scroller": "Posição da barra de rolagem",
                        "search": "Busca",
                        "searchBuilder": "Mecanismo de busca",
                        "select": "Selecionar",
                        "title": "Criar novo estado",
                        "toggleLabel": "Inclui:"
                    },
                    "emptyStates": "Nenhum estado salvo",
                    "removeConfirm": "Confirma remover %s?",
                    "removeJoiner": "e",
                    "removeSubmit": "Remover",
                    "removeTitle": "Remover estado",
                    "renameButton": "Renomear",
                    "renameLabel": "Novo nome para %s:",
                    "renameTitle": "Renomear estado",
                    "duplicateError": "Já existe um estado com esse nome!",
                    "emptyError": "Não pode ser vazio!",
                    "removeError": "Falha ao remover estado!"
                },
                "infoEmpty": "Mostrando 0 até 0 de 0 registro(s)",
                "processing": "Carregando...",
                "searchPlaceholder": "Buscar registros"
            }
        }
        $.extend($.fn.dataTable.defaults, {
            ...DTCommonConfig
        });
    }
};


// Funções auxiliares
const customHelper = ((fn) => {

    fn.openModal = function (element, events) {
        const me = $(element),
            data = $(me).data(),
            url = data.href || me.attr("href") || me[0].href,
            modal = data.modal || me[0].modal;

        $.get(url, r => {
            if (r.type) {
                fn.toast(r);
            } else {
                $("body").append(r);
                $(modal)
                    .on("hidden.bs.modal", () => {
                        if (events && events.onClose) events.onClose($(modal));
                        $(modal).remove();
                    })
                    .on("show.bs.modal", function () {
                        autoload();
                        if (events && events.onOpen) events.onOpen($(modal));
                        $(document).off("focusin.modal");
                    })
                    .on("shown.bs.modal", function () {
                        const body = $("body");
                        if (!body.hasClass("modal-open")) {
                            body.addClass("modal-open");
                        }
                    })
                    .modal("show");
            }
        });
    };

    fn.timer = function (display, onTime) {
        const me = this;
        let interval = null;

        me.delay = 1000;
        me.display = $(display);
        me._h = 0;
        me._m = 0;
        me._s = 0;

        me.display.addClass("text-muted");

        const leftZero = function (val) {
            if (parseInt(val) <= 9) {
                return "0" + val;
            }

            return val;
        };

        me.setDelay = function (n) {
            me.delay = n;
        };

        me.setDisplay = function (e, root) {
            me.display = $(e, root);
        };

        me.setTimer = function (h, m, s) {
            me._h = parseInt(h) || 0;
            me._m = parseInt(m) || 0;
            me._s = parseInt(s) || 0;
        };

        me.start = function (callback) {
            if (!interval) {
                var timeFormat;
                var _s = me._s || 0;
                var _m = me._m || 0;
                var _h = me._h || 0;

                me.display.removeClass("text-muted");

                interval = setInterval(function () {
                    if (_s < 59) {
                        _s++;
                    } else {
                        _s = 0;
                        _m++;

                        if (_m >= 59) {
                            _m = 0;
                            _h++;
                        }
                    }

                    timeFormat = leftZero(_h) + ":" + leftZero(_m) + ":" + leftZero(_s);

                    if (onTime) onTime(timeFormat);

                    me.setTimer(_h, _m, _s);

                    me.display.html(timeFormat);
                }, me.delay);
            }

            if (callback) callback();
        };

        me.stop = function (callback) {
            me.display.addClass("text-muted");
            clearInterval(interval);
            interval = null;

            if (callback) callback();
        };

        me.reset = function (callback) {
            me.stop();
            me.setTimer(0, 0, 0);
            interval = null;
            me.display.html("00:00:00");

            if (callback) callback();
        };

        me.toString = function () {
            return (me.timeFormat = leftZero(me._h) + ":" + leftZero(me._m) + ":" + leftZero(me._s));
        };

        return me;
    }

    fn.storage = function (item) {
        let me = this;

        me.get = () => {
            return localStorage.getItem(item);
        };

        me.set = value => {
            localStorage.setItem(item, value);
            return me;
        };

        me.del = () => {
            localStorage.removeItem(item);
        };

        return me;
    };

    fn.zipcodeService = function (options) {
        let defaults = $.extend(true, {
            urlProvider: 'https://viacep.com.br/ws',
            dataType: 'json',
            cep: null,
            trigger: 'click',
            bind: {
                cep: '#cep',
                logradouro: '#logradouro',
                complemento: '#complemento',
                bairro: '#bairro',
                localidade: '#cidade',
                uf: '#estado',
                unidade: '#unidade',
                ibge: '#ibge',
                gia: '#gia'
            },
            beforeSend: null,
            notFound: null,
            done: null,
            onError: null,
        }, options);

        let self = $(this);

        const exec = function (cep) {
            if (defaults.beforeSend) defaults.beforeSend(self);

            let query = $.get(`${defaults.urlProvider}/${cep}/${defaults.dataType}`);
            query.always(r => {
                if (r.statusText === "error") {
                    if (defaults.onError) {
                        defaults.onError();
                    } else {
                        if (defaults.done) defaults.done();
                        return false;
                    }
                } else {
                    if (r.erro) {
                        if (defaults.notFound) defaults.notFound();
                    } else {
                        if (defaults.bind) {
                            $.each(r, function (k, v) {
                                if (k === 'cep' && $(defaults.bind.cep).length > 0) {
                                    if ($(defaults.bind.cep).is('input')) $(defaults.bind.cep).val(v); else $(defaults.bind.cep).text(v);
                                }

                                if (k === 'logradouro' && $(defaults.bind.logradouro).length > 0) {
                                    if ($(defaults.bind.logradouro).is('input')) $(defaults.bind.logradouro).val(v); else $(defaults.bind.logradouro).text(v);
                                }

                                /*if (k == 'complemento' && $(defaults.bind.complemento).length > 0) {
                                if ($(defaults.bind.complemento).is('input')) $(defaults.bind.complemento).val(v); else $(defaults.bind.complemento).text(v);
                                }*/

                                if (k === 'bairro' && $(defaults.bind.bairro).length > 0) {
                                    if ($(defaults.bind.bairro).is('input')) $(defaults.bind.bairro).val(v); else $(defaults.bind.bairro).text(v);
                                }

                                if (k === 'localidade' && $(defaults.bind.localidade).length > 0) {
                                    if ($(defaults.bind.localidade).is('input')) $(defaults.bind.localidade).val(v); else $(defaults.bind.localidade).text(v);
                                }

                                if (k === 'uf' && $(defaults.bind.uf).length > 0) {
                                    if ($(defaults.bind.uf).is('input') || $(defaults.bind.uf).is('select')) $(defaults.bind.uf).val(v); else $(defaults.bind.uf).text(v);
                                }

                                if (k === 'unidade' && $(defaults.bind.cep).length > 0) {
                                    if ($(defaults.bind.unidade).is('input')) $(defaults.bind.unidade).val(v); else $(defaults.bind.unidade).text(v);
                                }

                                if (k === 'ibge' && $(defaults.bind.ibge).length > 0) {
                                    if ($(defaults.bind.ibge).is('input')) $(defaults.bind.ibge).val(v); else $(defaults.bind.ibge).text(v);
                                }

                                if (k === 'gia' && $(defaults.bind.gia).length > 0) {
                                    if ($(defaults.bind.gia).is('input')) $(defaults.bind.gia).val(v); else $(defaults.bind.gia).text(v);
                                }
                            });
                            if (defaults.onSuccess) defaults.onSuccess();
                        }
                    }
                }
                if (defaults.done) defaults.done();
            });
        };

        $(this).on(defaults.trigger, function (e) {
            e.preventDefault();
            let cep = (typeof (defaults.cep) === 'function') ? defaults.cep() : defaults.cep;
            exec(cep.replace(/\D/g, ''));
        });
    };

    fn.showConfirm = function (options) {
        var defaults = $.extend(
            {
                title: "Confirmar?",
                trigger: "click"
            },
            options
        );

        var isAjax = typeof defaults.ajax === "object";

        var swalOptions = {
            type: defaults.type || "warning",
            allowEscapeKey: false,
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        };

        if (typeof defaults.text !== "undefined") swalOptions.text = defaults.text;
        swal.setDefaults(swalOptions);

        $.each(this, function () {
            var me = $(this);
            me.on(defaults.trigger, function (e) {
                e.preventDefault();
                swal(
                    {
                        title: defaults.title,
                        showLoaderOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            if (defaults.onConfirm) {
                                if (isAjax) {
                                    $.ajax(defaults.ajax).done(function (xhr) {
                                        defaults.onConfirm(xhr, me);
                                        swal.close();
                                    });
                                } else {
                                    defaults.onConfirm(me);
                                    swal.close();
                                }
                            } else {
                                swal.close();
                            }
                        } else {
                            if (defaults.onCancel) defaults.onCancel();
                            swal.close();
                        }
                    }
                );
            });
        });
    };

    fn.toast = (json, callback) => {
        const {type, message} = json;
        if (typeof message === 'object') {
            Object.entries(message).forEach(([key, value]) => {
                toastr[type](value);
            });
        } else {
            toastr[type](message);
        }

        if (callback) callback(type);
    }

    fn.confirm = (options) => {
        let _settings = {
            playSound: {
                path: 'application/views/theme/dist/media/sound',
                file: 'bigbox',
                enabled: true
            },
            title: '',
            message: '',
            centerVertical: true,
            onEscape: false,
            backdrop: true,
            swapButtonOrder: true,
            buttons: {
                confirm: {
                    label: 'SIM',
                    className: 'btn-primary shadow-0'
                },
                cancel: {
                    label: 'NÃO',
                    className: 'btn-default'
                }
            },
            className: 'modal-alert',
            closeButton: false,
            callback: function (result) {
                return result
            },
            ...options
        };

        if (_settings.playSound.enabled && initApp) {
            initApp.playSound(_settings.playSound.path, _settings.playSound.file);
        }

        bootbox.confirm(_settings);
    }

    fn.alert = (options) => {
        let _settings = {
            playSound: {
                path: 'application/views/theme/dist/media/sound',
                file: 'voice_on',
                enabled: true
            },
            title: "",
            message: "",
            centerVertical: true,
            onEscape: false,
            backdrop: true,
            swapButtonOrder: true,
            className: "modal-alert",
            closeButton: false,
            callback: function (result) {
                return result
            },
            ...options
        };

        if (_settings.playSound.enabled && initApp) {
            initApp.playSound(_settings.playSound.path, _settings.playSound.file);
        }

        bootbox.alert(_settings);
    }

    fn.imagePreview = function (input, target, callback) {
        if (window.FileReader) {
            input.change(function () {
                let fileReader = new FileReader(),
                    files = this.files,
                    file;

                if (!files.length) return;

                file = files[0];

                if (/^image\/\w+$/.test(file.type)) {
                    fileReader.readAsDataURL(file);
                    fileReader.onload = function () {
                        target.attr('src', this.result);
                        if (callback) callback(this.result);
                    };
                } else {
                    console.error("Informe um arquivo de imagem.");
                }
            });
        }
    };

    fn.copy = (value)=> {
        navigator.clipboard.writeText(value)
            .then(()=>{
                toastr['success']('Copiado!')
            })
            .catch(e => toastr['error'](e.message));
    }

    /**
     * @author Jonas Santos Menezes
     * @description Passe uma string de data, e retornará uma string formatada em dia/mês/ano, se o segundo parâmetro for true retornará o formato ano/mês/dia, o terceiro argumento é o caractere de separação, como o / e o -
     * @param value
     * @param reverse
     * @param separator
     * @returns string
     */
    fn.brDate = function (value, reverse = false, separator = "/") {
        if(!value) {
            value = new Date();
        }

        if(value.toString().includes("/")) {
            let dia = value.replace("\\", "").split("/")[0], mes = value.split("/")[1], ano = value.split("/")[2];
            value = new Date(`${ano}-${mes}-${dia}T00:00:00`);
        } else {
            if(value && !value.toString().includes("T"))
                value = new Date(`${value}T00:00:00`);
            else
                value = new Date(value);
        }

        let day = value.getDate().toString().length < 2 ? `0${value.getDate()}` : value.getDate(),
            month = value.getMonth() < 9? `0${value.getMonth() + 1}` : value.getMonth() + 1,
            year = value.getFullYear();

        if(reverse)
            return `${year}${separator}${month}${separator}${day}`;
        else
            return `${day}${separator}${month}${separator}${year}`;
    }

    /**
     * @author Jonas Santos Menezes
     * @since 2023-12-05
     * @description Função que consulta, primeiro internamente, segundo externamente -, e retorna os dados do CPF/CNPJ informados. Pode automatizar o preenchimento de campos, basta definir o autocomplete como true e renamedFields na estrutura [{id do campo, chave do objeto json}]
     * @param url
     * @param taxNumber
     * @param birthdate
     * @param autocomplete
     * @param renamedFields
     * @param textMessage
     * @param titleMessage
     * @param isRep
     * @returns {Promise<{}>}
     */
    fn.consult = async function (url, taxNumber, birthdate = null, autocomplete = false, renamedFields = null, textMessage = "", titleMessage = "", isRep = false) {
        try {
            await $.ajax(url, {
                data: {
                    taxNumber: taxNumber,
                    date: birthdate,
                    isRep: isRep,
                },
                dataType: 'json',
                async success(data) {
                    if(data.type === "error")
                    {
                        customHelper.toast({type: "error", message: data.message});
                        throw new Error(data.message);
                    }
                    else {
                        if (!autocomplete) {
                            return data;
                        }

                        const isCPF = taxNumber.toString().replace(/\D/g, "").length <= 11;
                        titleMessage = titleMessage !== '' ? titleMessage : 'Carregar Dados?';
                        textMessage = textMessage !== '' ? textMessage : `Foi encontrado um registro de ${isCPF ? "CPF" : "CNPJ"} sob o nome <strong>${data.Name.toUpperCase()}</strong>. Deseja autocompletar os campos com os dados encontrados?`;
                        const confirm = await Swal.fire({
                            title: titleMessage,
                            html: textMessage,
                            confirmButtonText: isRep === true ? 'Estou Ciente' : 'Sim',
                            showCancelButton: true,
                            cancelButtonText: isRep === true ? 'Cancelar' : 'Não',
                        });

                        if (!confirm.isConfirmed) {
                            return data;
                        }

                        for (const field of Object.keys(data)) {
                            let currentField = $(`#${field}`);
                            let empty = data[field] === '' || data[field] === null || data[field] === undefined;
                            let emptyField = $(currentField).val() === '' || $(currentField).val() === null || $(currentField).val() === undefined;
                            if (!currentField || empty || !emptyField) {
                                continue;
                            }
                            currentField.val(data[field])
                        }

                        if (renamedFields) {
                            for (const newField of renamedFields) {
                                let currentField = $(`#${newField.to}`);
                                let empty = data[newField.from] === '' || data[newField.from] === null || data[newField.from] === undefined;
                                let emptyField = $(currentField).val() === '' || $(currentField).val() === null || $(currentField).val() === undefined;
                                if (!currentField || empty || !emptyField) {
                                    continue;
                                }

                                if (newField.formatter) {
                                    currentField.val(newField.formatter(data[newField.from]))
                                } else {
                                    currentField.val(data[newField.from])
                                }
                            }
                        }
                    }
                    customHelper.toast({type: "success", message: "Dados retornados com sucesso!"})
                    if (data.docs === true) {
                        const checkForm = $('#repFormCheck'),
                            checkDoc = $('#repCheckDoc');

                        if (checkForm.length) {
                            checkForm.hide();
                        }
                        if (checkDoc.length) {
                            checkDoc.addClass('disabled');
                            $('#docTipo').removeAttr('required')
                        }
                    } else {
                        const checkForm = $('#repFormCheck'),
                            checkDoc = $('#repCheckDoc');

                        if (checkForm.length) {
                            checkForm.show();
                        }
                        if (checkDoc.length) {
                            checkDoc.removeClass('disabled');
                            $('#docTipo').attr('required', 'required')
                        }
                    }


                    return data;
                },
                error(jqXHR, textStatus, errorThrown) {
                    throw new Error(errorThrown);
                }
            })
        } catch (e) {
            customHelper.toast({type: "error", message: e});
        }
    }

    return fn;
})({});

addEventListener("beforeunload", function () {
    $("button, input, select, text")
        .attr("disabled", true)
        .addClass("disabled");
});

(() => autoload())();
